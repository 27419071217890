import * as Sentry from '@sentry/vue';
import { useAuthStore } from '@/stores/AuthStore.ts';
import {
  InternalServerError,
  NotImplementedError,
  BadGatewayError,
  ServiceUnavailableError,
  ConnectionTimeoutError,
  NetworkUnavailableError,
  CanceledError,
} from '@/composables/ApiError.ts';

export const captureException = function (
  error: unknown,
  { message, data = {} }: { message: string; data?: object },
) {
  if (
    error instanceof InternalServerError ||
    error instanceof NotImplementedError ||
    error instanceof BadGatewayError ||
    error instanceof ServiceUnavailableError ||
    error instanceof ConnectionTimeoutError ||
    error instanceof NetworkUnavailableError ||
    error instanceof CanceledError
  ) {
    return;
  }

  const authStore = useAuthStore();
  const user = authStore.user;
  const organization = user
    ? {
        organization_id: user.organization.id,
        organization_name: user.organization.name,
      }
    : {};

  Sentry.addBreadcrumb({ message, data: { ...organization, ...data } });

  Sentry.captureException(error);
};
