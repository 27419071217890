import { AxiosError } from 'axios';

export class ApiError extends AxiosError {
  constructor(message: string) {
    super(message);
    this.name = 'ApiError';
  }
}

export class BadRequestError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'BadRequestError';
  }
}

class UnauthorizedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

export class ForbiddenError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'ForbiddenError';
  }
}

export class NotFoundError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotFoundError';
  }
}

class NotAllowedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotAllowedError';
  }
}

class NotAcceptableError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotAcceptableError';
  }
}

class PreconditionFailedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'PreconditionFailedError';
  }
}

class UnsupportedMediaTypeError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'UnsupportedMediaTypeError';
  }
}

export class InternalServerError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'InternalServerError';
  }
}

export class NotImplementedError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NotImplementedError';
  }
}

export class ContentTooLargeError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'ContentTooLargeError';
  }
}

export class ConnectionTimeoutError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'ConnectionTimeoutError';
  }
}

export class BadGatewayError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'BadGatewayError';
  }
}

export class ServiceUnavailableError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'ServiceUnavailableError';
  }
}

export class NetworkUnavailableError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'NetworkUnavailableError';
  }
}

export class CanceledError extends ApiError {
  constructor(message: string) {
    super(message);
    this.name = 'CanceledError';
  }
}

interface ErrorData {
  detail: string | null;
}

export const handleApiError = (error: AxiosError) => {
  if (error.code === 'ECONNABORTED') {
    throw new ConnectionTimeoutError('Connection timeout');
  } else if (error.code === 'ENETWORK' || error.message.includes('Network Error')) {
    // Network unavailable
    throw new NetworkUnavailableError('Network unavailable');
  } else if (error.message === 'canceled') {
    // Canceled request , don't trhow error;
    throw new CanceledError('Canceled request');
  }

  if (!error.response) {
    throw new Error('An error occurred: ' + error.message);
  }

  const errorData: ErrorData = error.response.data as ErrorData;

  const message = errorData.detail || error.message;

  switch (error.response.status) {
    case 400:
      throw new BadRequestError(message);
    case 401:
      throw new UnauthorizedError(message);
    case 403:
      throw new ForbiddenError(message);
    case 404:
      throw new NotFoundError(message);
    case 405:
      throw new NotAllowedError(message);
    case 406:
      throw new NotAcceptableError(message);
    case 412:
      throw new PreconditionFailedError(message);
    case 413:
      throw new ContentTooLargeError(message);
    case 415:
      throw new UnsupportedMediaTypeError(message);
    case 500:
      throw new InternalServerError(message);
    case 501:
      throw new NotImplementedError(message);
    case 502:
      throw new BadGatewayError(message);
    case 503:
      throw new ServiceUnavailableError(message);
    default:
      throw new Error('An error occurred: ' + error.message);
  }
};
