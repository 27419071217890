import initApi from '@/client_heplers/utils.ts';
import { defineStore } from 'pinia';
import { ref } from 'vue';

import { NotificationsApi } from '@/client/api.ts';

import type {
  CustomNotificationPage,
  NotificationsApiGetNotificationsApiNotificationsGetRequest,
  NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest,
  NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest,
} from '@/client/api.ts';

import { handleApiError } from '@/composables/ApiError.ts';
import type { AxiosError } from 'axios';

export const useNotificationStore = defineStore('notifications', () => {
  const notificationsData = ref<CustomNotificationPage | null>(null);
  const newNotificationCount = ref(0);
  const isLoading = ref(false);

  const getNotifications = async (
    params: NotificationsApiGetNotificationsApiNotificationsGetRequest = {},
  ) => {
    try {
      isLoading.value = true;

      const notificationsApi = await initApi(NotificationsApi);

      const response = await notificationsApi?.getNotificationsApiNotificationsGet(params);

      if (!response) return;

      notificationsData.value = response.data;
    } catch (error) {
      handleApiError(error as AxiosError);
    } finally {
      isLoading.value = false;
    }
  };

  const updateNotification = async (
    params: NotificationsApiUpdateNotificationApiNotificationsNotificationIdPatchRequest,
  ) => {
    try {
      const notificationsApi = await initApi(NotificationsApi);

      await notificationsApi?.updateNotificationApiNotificationsNotificationIdPatch(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const updateBulkNotifications = async (
    params: NotificationsApiUpdateNotificationsApiNotificationsBulkPatchRequest,
  ) => {
    try {
      const notificationsApi = await initApi(NotificationsApi);

      await notificationsApi?.updateNotificationsApiNotificationsBulkPatch(params);
    } catch (error) {
      handleApiError(error as AxiosError);
    }
  };

  const setNewNotifications = (count: number) => {
    newNotificationCount.value = count;
  };

  return {
    notificationsData,
    getNotifications,
    updateNotification,
    setNewNotifications,
    newNotificationCount,
    updateBulkNotifications,
    isLoading,
  };
});
