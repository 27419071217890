import { fetchEventSource } from '@microsoft/fetch-event-source';

import { useAuth } from '@/composables/Auth.ts';

import { useNotificationStore } from '@/stores/NotificationStore.ts';

let failureCount = 0;
let ctrl = new AbortController();

export const startStream = async function () {
  const notificationStore = useNotificationStore();
  const { refreshToken } = useAuth();

  const token = localStorage.getItem('access_token');
  if (!token) return;

  if (ctrl) {
    ctrl.abort();
  }

  ctrl = new AbortController();

  await fetchEventSource(`${import.meta.env.VITE_API_URL}/api/notifications/unread/stream/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    onmessage(ev) {
      const parseData = JSON.parse(ev.data);

      if (parseData.type === 'unread_notifications') {
        notificationStore.setNewNotifications(parseData.count);
      }

      failureCount = 0;
    },
    signal: ctrl.signal,
    onerror(e) {
      failureCount += 1;
      ctrl.abort();

      if (failureCount <= 10) {
        refreshToken().then(() => {
          startStream();
        });
      }

      throw e;
    },
  });
};
