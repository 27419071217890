import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useStylesStore = defineStore('styles', () => {
  const drawerOpened = ref<boolean>(false);

  const toggleDrawer = (value: boolean) => {
    drawerOpened.value = value;
  };
  return { toggleDrawer, drawerOpened };
});
