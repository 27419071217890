// Default Drawer data-test-ids
export const defaultDrawerIds: { [key: string]: string } = {
  drawerProjectsIcon: 'drawer-project-icon',
  drawerOrganizationIcon: 'drawer-organization-icon',
  drawerVendorsIcon: 'drawer-vendors-icon',
  drawerSupportIcon: 'drawer-support-icon',
  drawerOpenIcon: 'drawer-open-icon',
  drawerCloseIcon: 'drawer-close-icon',
};

// Header data-test-ids
export const headerIds: { [key: string]: string } = {
  notificationsIcon: 'notifications-icon',
  notificationsUnreadIcon: 'notifications-unread-icon',
};

// Profile Modal data-test-ids
export const profileModalIds: { [key: string]: string } = {
  profileModalLogoutIcon: 'profile-modal-logout-icon',
  profileModalSettingsIcon: 'profile-modal-settings-icon',
  profileModalTokenIcon: 'profile-modal-token-icon',
};

// Evidence Preview Modal data-test-ids
export const evidencePreviewIds: { [key: string]: string } = {
  evidencePreviewCreatedByValue: 'evidence-preview-created-by-value',
  evidencePreviewTypeValue: 'evidence-preview-type-value',
  evidencePreviewLastUpdateValue: 'evidence-preview-last-update-value',
  evidencePreviewFileSizeValue: 'evidence-preview-file-size-value',
};

// New Evidence Modal data-test-ids
export const newEvidenceIds: { [key: string]: string } = {
  newEvidenceEditNameIcon: 'new-evidence-edit-name',
  newEvidenceDeleteNameIcon: 'new-evidence-delete-name',
  newEvidenceRefreshNameIcon: 'new-evidence-delete-name',
  newEvidenceEditDescriptionIcon: 'new-evidence-edit-description',
  newEvidenceDeleteDescriptionIcon: 'new-evidence-delete-description',
  newEvidenceRefreshDescriptionIcon: 'new-evidence-delete-name',
};

// Edot Evidence Modal data-test-ids
export const editEvidenceIds: { [key: string]: string } = {
  editEvidenceEditNameIcon: 'edit-evidence-edit-name',
  editEvidenceDeleteNameIcon: 'edit-evidence-delete-name',
  editEvidenceRefreshNameIcon: 'edit-evidence-delete-name',
  editEvidenceEditDescriptionIcon: 'edit-evidence-edit-description',
  editEvidenceDeleteDescriptionIcon: 'edit-evidence-delete-description',
  editEvidenceRefreshDescriptionIcon: 'edit-evidence-delete-name',
};

// Token Modal data-test-ids
export const tokenModalIds: { [key: string]: string } = {
  tokenModalCopyIcon: 'token-modal-copy-icon',
};

// Invited User Modal data-test-ids
export const invitedUserModalIds: { [key: string]: string } = {
  resendInvitationButton: 'resend-invitation-button',
  cancelInvitationButton: 'cancel-invitation-button',
};

// Control Evidence Component data-test-ids
export const controlEvidenceComponentIds: { [key: string]: string } = {
  controlEvidenceComponentPreviewEvidenceIcon: 'control-evidence-component-preview-evidence-icon',
  controlEvidenceComponentDeleteEvidenceIcon: 'control-evidence-component-delete-evidence-icon',
};

// Project Creation data-test-ids
export const projectCreationIds: { [key: string]: string } = {
  projectCreationAddUserButton: 'project-creation-add-user-button',
  projectCreationResetFrameworksButton: 'project-creation-reset-Frameworks-button',
};

// Mixed data-test-ids
export const mixedIds: { [key: string]: string } = {
  arrowBackButton: 'arrow-back-button',
  editTitleButton: 'edit-title-button',
  editDescriptionButton: 'edit-description-button',
};

// Vendor data-test-ids
export const createVendorIds: { [key: string]: string } = {
  createVendorNameInput: 'create-vendor-name-input',
  createVendorTypeSelect: 'create-vendor-type-select',
  createVendorWebsiteInput: 'create-vendor-website-input',
  createVendorDescriptionInput: 'create-vendor-description-input',
  createVendorAdressStreetInput: 'create-vendor-adress-street-input',
  createVendorAdressNumberInput: 'create-vendor-adress-number-input',
  createVendorAdressZipCodeInput: 'create-vendor-adress-zip-code-input',
  createVendorAdressCityInput: 'create-vendor-adress-city-input',
  createVendorAdressStateInput: 'create-vendor-adress-state-input',
  createVendorAdressCountrySelect: 'create-vendor-adress-country-select',
  createVendorResponsiblePersonInput: 'create-vendor-responsible-person-input',
  createVendorStatusSelect: 'create-vendor-status-select',
  createVendorRiskLevelSelect: 'create-vendor-risk-level-select',
  createVendorReviewDateInput: 'create-vendor-review-date-input',
  createVendorAnnualContractInput: 'create-vendor-annual-contract-input',
  createVendorSubprocessorCheckbox: 'create-vendor-subprocessor-checkbox',
  createVendorPrivacyPolicyInput: 'create-vendor-privacy-policy-input',
  createVendorTermsOfServiceInput: 'create-vendor-terms-of-service-input',
  createVendorAddButton: 'create-vendor-add-button',
};

export const editVendorIds: { [key: string]: string } = {
  editVendorNameInput: 'edit-vendor-name-input',
  editVendorTypeSelect: 'edit-vendor-type-select',
  editVendorWebsiteInput: 'edit-vendor-website-input',
  editVendorDescriptionInput: 'edit-vendor-description-input',
  editVendorAdressStreetInput: 'edit-vendor-adress-street-input',
  editVendorAdressNumberInput: 'edit-vendor-adress-number-input',
  editVendorAdressZipCodeInput: 'edit-vendor-adress-zip-code-input',
  editVendorAdressCityInput: 'edit-vendor-adress-city-input',
  editVendorAdressStateInput: 'edit-vendor-adress-state-input',
  editVendorAdressCountrySelect: 'edit-vendor-adress-country-select',
  editVendorResponsiblePersonSelect: 'edit-vendor-responsible-person-input',
  editVendorStatusSelect: 'edit-vendor-status-select',
  editVendorRiskLevelSelect: 'edit-vendor-risk-level-select',
  editVendorReviewDateInput: 'edit-vendor-review-date-input',
  editVendorAnnualContractInput: 'edit-vendor-annual-contract-input',
  editVendorSubprocessorCheckbox: 'edit-vendor-subprocessor-checkbox',
  editVendorPrivacyPolicyInput: 'edit-vendor-privacy-policy-input',
  editVendorTermsOfServiceInput: 'edit-vendor-terms-of-service-input',
  editVendorSaveButton: 'edit-vendor-save-button',
};

export const vendorDetailsIds: { [key: string]: string } = {
  vendorDetailsName: 'vendor-details-name',
  vendorDetailsWebsite: 'vendor-details-website',
  vendorDetailsDescription: 'vendor-details-description',
  vendorDetailsAdress: 'vendor-details-adress',
  vendorDetailsPrivacyPolicy: 'vendor-details-privacy-policy',
  vendorDetailsTermsOfService: 'vendor-details-terms-of-service',
  vendorDetailsResponsiblePerson: 'vendor-details-responsible-person',
  vendorDetailsStatus: 'vendor-details-status',
  vendorDetailsRiskLevel: 'vendor-details-risk-level',
  vendorDetailsReviewDate: 'vendor-details-review-date',
  vendorDetailsAnnualContract: 'vendor-details-annual-contract',
  vendorDetailsSubprocessor: 'vendor-details-subprocessor',
  vendorDetailsType: 'vendor-details-type',
};
